<template>
  <shared-tables-table
    :config="config"
    :title="$t('tables.rooms')"
    is-hotel
  />
</template>

<script>
import TablesTableConfig from '@/views/tables/config/hotel-tables-table-config'
import SharedTablesTable from '@/views/tables/SharedTablesTable'

export default {
  name: 'TablesPage',
  components: { SharedTablesTable },
  data() {
    return {
      config: TablesTableConfig,
    }
  },
  async mounted() {
    await this.$store.dispatch('tables/FETCH_HOTEL_FLOORS')
  },
}
</script>
