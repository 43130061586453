<template>
  <ok-modal
    :title="$t(`forms.${isEdit?'edit':'add'}`)"
    :value="value"
    dialog-class="tables-modal"
    size="lg"
    @change="$emit('input',false)"
  >
    <validation-observer ref="form">
      <error-alert
        v-if="error"
        :error="error"
      />
      <b-row>
        <b-col
          md="6"
        >
          <ok-text-input
            v-model="form.name"
            :hide-errors="false"
            :label="$t('fields.name')"
            rules="required"
          />
        </b-col>
        <b-col
          v-if="hasMultiBranches"
          md="6"
        >
          <branch-select
            v-model="form.branch_id"
            :hide-errors="false"
            :label="$t('fields.branch')"
            rules="required"
          />
        </b-col>
      </b-row>
    </validation-observer>
    <template #actions>
      <div class="d-flex justify-content-end align-items-center">
        <b-button
          :disabled="loading"
          variant="outline-dark"
          @click="submit"
        >
          <b-spinner
            v-if="loading"
            class="ml-1"
            small
          />
          {{ $t('forms.save') }}
        </b-button>
        <b-button
          class="ml-1"
          variant="dark"
          @click="$emit('input',false)"
        >
          {{ $t('forms.cancel') }}
        </b-button>
      </div>
    </template>
  </ok-modal>
</template>

<script>
import {
  BButton, BCol, BRow, BSpinner,
} from 'bootstrap-vue'
import OkModal from '@/@bya3/components/modals/OkModal'
import OkTextInput from '@/@bya3/components/inputs/OkTextInput'
import ErrorAlert from '@/@bya3/components/alerts/ErrorAlert'
import { ValidationObserver } from 'vee-validate'
import TablesApi from '@/api/store/tables-api'
import { mapGetters } from 'vuex'
import BranchSelect from '@/@bya3/components/inputs/BranchSelect'

export default {
  name: 'ProcessFloorModal',
  components: {
    BranchSelect,
    ErrorAlert,
    OkTextInput,
    ValidationObserver,
    OkModal,
    BButton,
    BRow,
    BCol,
    BSpinner,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    floor: {
      type: Object,
      default: () => ({}),
    },
    isHotel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: null,
      form: {
        name: '',
      },
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      branches: 'shop/branches',
    }),
    isEdit() {
      return !!this.floor?.id
    },
    hasMultiBranches() {
      return this.branches?.length > 1
    },
  },
  watch: {
    floor() {
      this.error = null
      this.setForm()
    },
  },
  mounted() {
    this.setForm()
  },
  methods: {
    setForm() {
      this.form = this.isEdit ? { ...this.floor } : { name: '' }
    },
    async submit() {
      this.error = null
      const valid = await this.$refs.form.validate()
      if (!valid) {
        this.error = this.$tc('errors.field-invalid', 2)
        this.notifyError = this.$tc('errors.field-invalid', 2)
      } else {
        this.loading = true
        const form = { ...this.form }
        if (this.isEdit) {
          form.id = this.floor.id
        }
        if (!this.hasMultiBranches) {
          form.branch_id = this.branches[0]?.id ?? null
        }
        await TablesApi.processFloor(form, this.isHotel).then(res => {
          if (res.error === 0) {
            if (res?.floor) {
              const storeLable = `tables/${this.isHotel ? 'UPDATE_HOTEL_FLOORS' : 'UPDATE_FLOORS'}`
              this.$store.commit(storeLable, res.floor)
            }
            this.$notifySuccess(res.message)
            this.$emit('updated')
            this.$emit('input', false)
          }
        }).catch(e => {
          this.error = e
        })
          .finally(() => {
            this.loading = false
          })
      }
    },
  },
}
</script>
