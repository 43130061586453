<template>
  <div>
    <ok-table
      ref="datatable"
      :config="config"
      :title="tableTitle"
      @delete="handleDelete"
      @edit="openFloorModal"
    >
      <template slot="buttons">
        <b-button
          class="mx-md-1"
          size="md"
          variant="dark"
          @click="openFloorModal"
        >
          {{ $t('tables.add-floor') }}
        </b-button>
      </template>
    </ok-table>
    <process-floor-modal
      v-model="floorModal"
      :floor="selectedFloor"
      :is-hotel="isHotel"
      @updated="refreshTable"
    />
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import TablesAPIs from '@/api/store/tables-api'
import ProcessFloorModal from '@/views/tables/components/ProcessFloorModal'

export default {
  name: 'SharedFloorTable',
  components: {
    ProcessFloorModal,
    BButton,
    OkTable: () => import(/* webpackChunkName: "ok-table" */ '@/@bya3/components/tables/ok-table/OkTable'),
  },
  props: {
    config: {
      type: Object,
      default: () => {
      },
    },
    isHotel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      floorModal: false,
      selectedFloor: {},
    }
  },
  computed: {
    tableTitle() {
      return this.$t('')
    },
  },
  methods: {
    refreshTable() {
      this.selectedFloor = {}
      this.$refs.datatable.loadItems()
    },
    openFloorModal(row) {
      this.selectedFloor = row?.row ?? null
      this.floorModal = true
    },
    handleDelete(data) {
      this.$okConfirm(this.$t('general.delete-confirm'), {
        preConfirm: () => TablesAPIs.deleteFloor(data.row.id, this.isHotel)
          .then(response => {
            this.$notifySuccess(response.message)
            this.refreshTable()
          })
          .catch(e => this.$notifyError(e)),
      })
    },
  },
}
</script>
