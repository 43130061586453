<template>
  <validation-provider
    #default="{ errors }"
    :name="name"
    rules="required"
  >
    <b-form-group
      :label="$t('tables.floor')"
      :label-cols-lg="labelColsLg"
      :state="errors.length ? false : null"
      class="position-relative"
      label-class="required pt-0"
    >
      <v-select
        :class="{'select-size-lg': size === 'lg', 'select-size-sm': size === 'sm'}"
        :clearable="false"
        :dir="isRTL ? 'rtl' : 'ltr'"
        :multiple="false"
        :options="options"
        :placeholder="$t('tables.floor')"
        :reduce="option => option.id"
        :value="value"
        autocomplete="off"
        label="name"
        @input="$emit('input', $event)"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'
import { BFormGroup } from 'bootstrap-vue'

export default {
  name: 'FloorSelect',
  components: {
    vSelect,
    ValidationProvider,
    BFormGroup,
  },
  props: {
    value: {
      type: [Number, String],
      default: '',
    },
    labelColsLg: {
      type: [Number, String],
      default: 12,
    },
    name: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
    exclude: {
      type: Array,
      default: () => ([]),
    },
    isHotel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    options() {
      const storeLabel = this.isHotel ? 'tables/hotelFloors' : 'tables/floors'
      return this.$store.getters[storeLabel] ?? []
    },
  },
}
</script>
