<template>
  <shared-floor-table :config="config" />
</template>

<script>
import SharedFloorTable from '@/views/tables/SharedFloorsTable'
import FloorsTableConfig from '@/views/tables/config/floors-table-config'

export default {
  name: 'FloorsPage',
  components: { SharedFloorTable },
  data() {
    return {
      config: FloorsTableConfig,
    }
  },
}
</script>
