<template>
  <div>
    <ok-table
      ref="datatable"
      :config="config"
      :title="title"
      @delete="handleDelete"
      @edit="openFloorModal"
      @qr="openQRModal"
      @update:total="toggleShowQR"
    >
      <template slot="buttons">
        <b-button
          class="mx-md-1"
          size="md"
          variant="dark"
          @click="openFloorModal"
        >
          {{ $t(`tables.add-${isHotel ? 'room' : 'table'}`) }}
        </b-button>
        <b-button
          v-if="showGenerateAll"
          class="mx-md-1"
          size="md"
          variant="outline-dark"
          @click="openQRModal"
        >
          {{ $t(`tables.qr-all-${isHotel ? 'rooms' : 'tables'}`) }}
        </b-button>
      </template>
    </ok-table>
    <process-table-modal
      v-model="tableModal"
      :is-hotel="isHotel"
      :table="selectedTable"
      @updated="refreshTable"
    />
    <q-r-modal
      v-model="showQRModal"
      :is-hotel="isHotel"
      :table="selectedTable"
      all
    />
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import TablesAPIs from '@/api/store/tables-api'
import ProcessTableModal from '@/views/tables/components/ProcessTableModal'
import QRModal from '@/views/tables/components/QRModal'

export default {
  name: 'SharedTablesTable',
  components: {
    QRModal,
    ProcessTableModal,
    BButton,
    OkTable: () => import(/* webpackChunkName: "ok-table" */ '@/@bya3/components/tables/ok-table/OkTable'),
  },
  props: {
    config: {
      type: Object,
      default: () => {
      },
    },
    isHotel: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tableModal: false,
      showQRModal: false,
      selectedTable: {},
      showGenerateAll: false,
    }
  },
  methods: {
    toggleShowQR(num) {
      this.showGenerateAll = num > 1
    },
    refreshTable() {
      this.selectedTable = {}
      this.tableModal = false
      this.$refs.datatable.loadItems()
    },
    openFloorModal(row) {
      this.selectedTable = row?.row ?? null
      this.tableModal = true
    },
    openQRModal(row) {
      this.selectedTable = row?.row ?? null
      this.showQRModal = true
    },
    handleDelete(data) {
      this.$okConfirm(this.$t('general.delete-confirm'), {
        preConfirm: () => TablesAPIs.delete(data.row.id, this.isHotel)
          .then(response => {
            this.$notifySuccess(response.message)
            this.refreshTable()
          })
          .catch(e => this.$notifyError(e)),
      })
    },
  },
}
</script>
