import OkTableConfigType from '@/@bya3/components/tables/ok-table/OkTableConfigType'
import { API_URL } from '@/constants/config'
import CommonTableActions from '@/constants/common-table-actions'

const TablesTableConfig: OkTableConfigType = {
  mode: 'remote',
  url: `${API_URL}/tables`,
  rowsPath: 'tables',
  rowClickable: true,
  columns: [
    {
      label: 'tables.table-number',
      field: 'number',
    },
    {
      label: 'tables.people-count',
      field: 'capacity',
    },
    {
      label: 'tables.floor',
      field: 'floor',
    },
  ],
  paginationOptions: {
    enabled: true,
  },
  sortOptions: {
    enabled: false,
  },
  actions: [
    ...CommonTableActions,
    {
      label: 'tables.qr',
      eventName: 'qr',
      icon: 'MaximizeIcon',
      variant: 'primary',
    },
  ],
  filters: [],
}

export default TablesTableConfig
