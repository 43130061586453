<template>
  <ok-modal
    :title="$t(`tables.qr-all-${isHotel ? 'rooms' : 'tables'}`)"
    :value="value"
    dialog-class="tables-modal"
    size="lg"
    @change="$emit('input',false)"
  >
    <q-r-form
      ref="qrForm"
      :is-hotel="isHotel"
      :table="table"
      is-for-table
    />
    <b-row>
      <b-col
        v-if="!hasTable && hasMultiBranches"
        md="6"
      >
        <branch-select
          v-model="branch_id"
          :hide-errors="false"
          :label="$t('fields.branch')"
          rules="required"
        />
      </b-col>
    </b-row>
    <template #actions>
      <div class="d-flex justify-content-end align-items-center">
        <b-button
          v-if="!hasTable"
          :disabled="loading"
          variant="outline-dark"
          @click="submit"
        >
          <b-spinner
            v-if="loading"
            class="ml-1"
            small
          />
          {{ $t('qr.generate') }}
        </b-button>
        <b-button
          class="ml-1"
          variant="dark"
          @click="$emit('input',false)"
        >
          {{ $t(`forms.${!hasTable ? 'cancel' : 'close'}`) }}
        </b-button>
      </div>
    </template>
  </ok-modal>
</template>

<script>
import {
  BButton, BCol, BRow, BSpinner,
} from 'bootstrap-vue'
import OkModal from '@/@bya3/components/modals/OkModal'
import QRForm from '@/views/theme/components/QRForm'
import BranchSelect from '@/@bya3/components/inputs/BranchSelect'
import { mapGetters } from 'vuex'

export default {
  name: 'QRModal',
  components: {
    BranchSelect,
    QRForm,
    OkModal,
    BButton,
    BSpinner,
    BRow,
    BCol,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    table: {
      type: Object,
      default: () => ({}),
    },
    all: {
      type: Boolean,
      default: false,
    },
    isHotel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: null,
      loading: false,
      branch_id: null,
    }
  },
  computed: {
    ...mapGetters({
      branches: 'shop/branches',
    }),
    hasMultiBranches() {
      return this.branches?.length > 1
    },
    hasTable() {
      return this.table?.id
    },
  },
  mounted() {
    this.branch_id = this.branches[0]?.id ?? null
  },
  methods: {
    async submit() {
      this.error = null
      if (!this.hasMultiBranches) {
        this.branch_id = this.branches[0]?.id ?? null
      }
      this.loading = true
      await this.$refs.qrForm.downloadZip(this.branch_id)
      this.loading = false
    },
  },
}
</script>
