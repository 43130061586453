import OkTableConfigType from '@/@bya3/components/tables/ok-table/OkTableConfigType'
import { API_URL } from '@/constants/config'
import store from '@/store'

const FloorsTableConfig: OkTableConfigType = {
  mode: 'remote',
  url: `${API_URL}/floors`,
  rowsPath: 'floors',
  rowClickable: true,
  columns: [
    {
      label: 'fields.name',
      field: 'name',
    },
    {
      label: 'fields.branch',
      field: 'branch',
    },
    {
      label: 'tables.tables-count',
      field: 'tables_count',
    },
  ],
  paginationOptions: {
    enabled: true,
  },
  sortOptions: {
    enabled: false,
  },
  actions: [
    {
      // label: 'forms.edit',
      eventName: 'edit',
      icon: 'Edit2Icon',
      variant: 'warning',
    },
    {
      // label: 'forms.delete',
      eventName: 'delete',
      icon: 'Trash2Icon',
      variant: 'danger',
      disabled: (row: any) => row.main === 1,
    },
  ],
  filters: [
    {
      label: 'fields.name',
      query: 'name',
      colLength: 3,
    },
    {
      label: 'fields.branch',
      query: 'branch',
      colLength: 3,
      type: 'select',
      multiple: false,
      options: store.getters['auth/branches'],
      optionsLabel: { keyLabel: 'ar_name', valueLabel: 'id', translate: false },
    },
  ],
}

export default FloorsTableConfig
